<style lang="scss" scoped>
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}
</style>

<template>
	<div class="upfitter">
		<header-nav :hasLogout="!Boolean(userDesc)">{{ navText }}</header-nav>

		<protect-limit ref="protect" :productId="productId" :planId="planId" plan @change="protectLimitChange" time>
			<span slot="title">投保信息</span>
		</protect-limit>
		<split-line></split-line>

		<insure-form ref="insure" :fixed="false" :fill="!Boolean(userDesc)"></insure-form>
		<split-line></split-line>

		<!-- 被保人信息 -->
		<assureds ref="assured" :sameInsure="true" :assuredBook="!Boolean(userDesc)" :companyId="companyId" @change="totalPriceChange"></assureds>
		<split-line></split-line>

		<!-- 条款 -->
		<clause v-model="check" :productId="productId"></clause>

		<page-footer></page-footer>
		<price-buy-btn :price="price" @buy="buy"></price-buy-btn>

		<loading :show="loading">提交中</loading>
	</div>
</template>

<script>
import { Toast } from 'vant';
import splitLine from '../components/splitLine.vue';
import protectLimit from '../components/protectLimit.vue';
import insureForm from '../components/insureForm.vue';
import subjectForm from '../components/subjectForm.vue';
import clause from '../components/clause.vue';
import assureds from '../components/assureds.vue';
import { http_submitBill, http_getPalns } from '@/request/sundry';
import headerNav from '../components/headerNav.vue';
export default {
	name: 'upfitter', // 华安公众责任险
	components: {
		splitLine,
		protectLimit,
		insureForm,
		subjectForm,
		assureds,
		clause,
		headerNav,
		[Toast.name]: Toast,
	},
	data() {
		return {
			pid: '',
			userDesc: '',
			navText: '装修工人意外险-计划一',
			productId: '',
			planId: '',

			scrollY: 0,

			check: false,

			companyId: '', // 保险公司id

			priceId: '',
			protectInfo: {}, // 保障期限信息

			totalPrice: 0, // 总价
			loading: false,
		};
	},
	computed: {
		price: function () {
			return this.$base.floatPrice(this.totalPrice);
		},
	},
	created() {
		this.init();
		this.getPlain();
	},
	beforeRouteLeave(to, from, next) {
		if (to.path.includes('/sundry/product')) {
			khs.stop();
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		back() {
			this.$router.go(-1);
		},

		init() {
			let query = this.$route.query;
			this.pid = query.pid;
			this.userDesc = query.user;
			this.navText = `${query.pName}-${query.planName}`;
			this.productId = query.productId;
			this.planId = query.plainId;
			this.companyId = query.companyId;

			this.priceId = query.priceId;
		},

		// 去登录页
		toLoginPage(err) {
			if (err.message === '用户未登录') {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 获取计划
		getPlain() {
			if (this.$store.state.priceList.length) {
				return;
			}
			http_getPalns(this.productId)
				.then(res => {
					this.$store.commit('set_priceList', res.filter(item => String(item.id) === String(this.planId))[0].productPriceList);
				})
				.catch(err => {
					if (err.message === '用户未登录') {
						this.$router.push('login');
					}
				});
		},

		// 保障期限改变
		protectLimitChange(info) {
			this.protectInfo = info;
		},
		// 总价格改变
		totalPriceChange(price) {
			this.totalPrice = price;
		},

		async buy() {
			if (!this.check) {
				Toast('请先勾选条款');
				return false;
			}

			// 获取投保信息
			let protectData = await this.$refs.protect.getData();
			// 获取投保人信息
			let insureData = await this.$refs.insure.getData();

			// 获取被保险人
			let assured = await this.$refs.assured.getData();

			// 按职业类别把被保人分类
			let assuredsByJob = {};
			let priceInfos = {};
			assured.forEach(item => {
				if (!assuredsByJob[String(item.jobDict.profCode)]) {
					assuredsByJob[String(item.jobDict.profCode)] = [];
				}
				assuredsByJob[String(item.jobDict.profCode)].push({
					birthday: item.birthday,
					certificateContent: item.cardNum,
					certificateType: item.cardTypeCode,
					mobile: item.phone,
					name: item.userName,
					profession: item.jobDict.profCode,
					relation: item.relationCode,
					sex: item.sex,
				});
				priceInfos[String(item.jobDict.profCode)] = item.priceInfo;
			});

			// let assuredList = assured.map(item => {
			//     return {
			//         "birthday": item.birthday,
			//         "certificateContent": item.cardNum,
			//         "certificateType": item.cardTypeCode,
			//         "mobile": item.phone,
			//         "name": item.userName,
			//         "profession": item.jobDict.profCode,
			//         "relation": item.relationCode,
			//         "sex": item.sex,
			//     }
			// })

			let send = {
				insBill: {
					proId: this.productId,
					enableDate: protectData.start,
					disEnableDate: protectData.end,
					assuredSendMsg: 1,
				},
				insurer: {
					fixedFlag: Number(insureData.isFixedInsure),
					insAddress: insureData.address,
					insBirthday: insureData.birthday,
					insCredentials: insureData.cardNum,
					insCredentialsType: insureData.cardType,
					insEmail: insureData.email,
					insName: insureData.userName,
					insPhone: insureData.phone,
					insSex: insureData.sex,
					insType: insureData.insType,
					saveContactsFlag: Number(insureData.isFixedInsure),
				},
				// "assuredList": assuredList,
				productPriceVo: {
					comboId: this.planId,
					priceId: this.protectInfo.price_id,
					proId: this.productId,
					eleUse1: '',
					eleView1: '',
					insureTime: this.protectInfo.insure_time,
					insureTimeUnit: this.protectInfo.insure_time_unit,
					sex: '',
					viewAgeBelong: '',
					viewTime: this.protectInfo.view_time,
				},
				extentionList: [],
			};

			let sends = [];
			// 请求方法
			let requests = [];
			// 构建请求方法
			for (const key in assuredsByJob) {
				if (Object.hasOwnProperty.call(assuredsByJob, key)) {
					const assuredList = assuredsByJob[key];
					let s = Object.assign({}, send, { assuredList });
					s.productPriceVo.priceId = priceInfos[key].priceId;
					s.productPriceVo.eleUse1 = priceInfos[key].eleUse1;
					s.productPriceVo.eleView1 = priceInfos[key].eleView1;
					sends.push(s);
					requests.push(http_submitBill(s));
				}
			}

			this.submit(requests);
		},

		// 提交订单
		submit(requests) {
			this.loading = true;
			Promise.all(requests)
				.then(res => {
					let ids = res.map(item => item.data.id);
					khs.finish(res[0].data.id);
					this.$store.commit('set_billId', ids.join(','));
					this.$router.push({
						name: 'orderPay',
						params: {
							isRedict: false,
						},
					});
				})
				.catch(err => {
					this.toLoginPage(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
